@import 'styles/custom/includes';

@mixin mobileScreenVideoAPP() {
    .video-app-content {
        height: 584px;
        flex-direction: column;
        justify-content: center;

        .video-app-content-left {
            flex: 2;

            .text-description-container {
                font-size: 66px;
            }
        }

        .video-app-content-right {
            .pbs-content {
                //flex-direction: column;
                flex-direction: row;
                justify-content: center;


                .pbs-download-app-container {
                    .download-app-content {
                        font-weight: $black;
                        font-size: 27px;
                        max-height: 47px;
                        max-width: 188px;
                    }

                    .app-additional-info {
                        font-size: 23px;
                    }
                }
            }
        }
    }

}

@mixin responsiveVideoBackground($banner-url) {
    background-image: url($banner-url);
    background-size: cover;
    background-position: center;
}

.component .video-app-component {
    container-type: inline-size;

    .video-app-content {
        height: 200px;
        display: flex;
        flex-direction: row;
        border: 2px solid $azureish-white;

        //@include responsiveVideoBackground('/static/app/bento-components/video-app/media/pbs_app_resolution_1440.png');
        @include responsiveVideoBackground('../../app/bento-components/video-app/media/pbs_app_resolution_1440.png');


        .video-app-content-left {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1;
            margin: auto;

            .text-description-container {
                color: $white;
                font-size: clamp(28px, 5cqi, 54px);
                width: 100%;
                text-align: center;

                .color-text-description {
                    font-weight: $bold;
                    color: $yellow;
                }

                .bold-text-description {
                    font-weight: $bold;
                }
            }
        }

        .video-app-content-right {
            height: 100%;
            align-items: center;
            flex: 1;

            display: flex;
            flex-direction: column;
            container-type: inline-size;

            .pbs-content {
                align-self: center;
                margin-top: auto;
                display: flex;
                color: $white;
                width: 100%;

                justify-content: center;
                flex-direction: row;

                @media (max-width: 1024px) {
                    flex-direction: column;
                    align-items: center;
                }



                .pbs-logo-container {
                    margin-right: 5%;
                    justify-content: center;
                    display: flex;
                    width: clamp(3.208vw, 35%, 100vw);
                    height: clamp(85px, 2vw, 190px);

                    .logo-image {
                        max-height: 100%;
                        max-width: 100%;
                    }
                }

                .pbs-download-app-container {
                    padding-right: 15px;
                    display: flex;
                    flex-direction: column;
                    margin-top: auto;
                    margin-bottom: auto;

                    gap: 15px;

                    .download-app-content {

                        font-weight: $black;
                        font-size: clamp(15px, 2vw, 26px);
                        border: 2px solid $yellow;
                        border-radius: 0.5em;
                        background-color: $yellow;
                        color: $pbs-blue;
                        text-align: center;
                        cursor: pointer;

                        max-width: 217px;
                        max-height: 55px;
                        box-sizing: border-box;

                        &:hover {
                            background-color: #0F1E8C;
                            border: solid 2px #0F1E8C;
                            color: $yellow;
                            max-width: 217px;
                            max-height: 55px;
                        }
                        &:active {
                            border: solid 3px #0F1E8C;
                            background-color: #0F1E8C;
                            color: $yellow;
                        }
                    }


                    .app-additional-info {
                        font-size: clamp(15px, 2vw, 26px);
                    }


                }

            @container (width < 500px) {
                flex-direction: column;
                align-items: center;

                .pbs-download-app-container {
                    padding-right: 0;
                    gap: 7px;


                    .download-app-content, .app-additional-info {
                        font-size: clamp(15px, 2vw, 18px);
                    }
                }
            }


            }

            .additional-info {
                margin-top: 25px;
                color: #C0CBDA;
                font-size: 12px;
                font-weight: $normal;
                font-family: "PBSSans", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
                padding-left: 10%;
                padding-right: 5%;
                padding-bottom: 2%;
            }
        }
    }

    @container (max-width: 500px) {

        .video-app-content {
            height: 537px;
            flex-direction: column;
            justify-content: center;

            @include responsiveVideoBackground('../../app/bento-components/video-app/media/pbs_app_resolution_769.png');


            .video-app-content-left {
                flex: 2;
                .text-description-container {
                    font-size: clamp(38px, 5cqi, 66px);
                }
            }

            .video-app-content-right {
                .pbs-content {
                    margin-top: 45px;
                    flex-direction: row;
                    justify-content: center;

                }
            }
        }
    }

    @container (max-width: 375px) {

        .video-app-content {
            height: 481px;

            @include responsiveVideoBackground('../../app/bento-components/video-app/media/pbs_app_resolution_375.png');
            .video-app-content-left {
                flex: 1;
            }

            .video-app-content-right {
                .pbs-content {
                    flex-direction: column;
                    margin-top: 5px;
                }

                .additional-info {
                    margin-top: 0;
                }
            }
        }
    }



    @media (max-width: $md) {

    @container (max-width: 500px) {

      .video-app-content {
        height: 537px;
        flex-direction: column;
        justify-content: center;

        @include responsiveVideoBackground('../../app/bento-components/video-app/media/pbs_app_resolution_769.png');


        .video-app-content-left {
          flex: 2;
        }

        .video-app-content-right {
          .pbs-content {
            flex-direction: row;
            justify-content: center;

          }
        }
      }
    }

    @container (max-width: 375px) {

        .video-app-content {
            .video-app-content-right {
                .pbs-content {
                    flex-direction: column;
                }
            }
        }
    }
    }


    @media (max-width: $sm) {
        .video-app-content {
            @include responsiveVideoBackground('../../app/bento-components/video-app/media/pbs_app_resolution_769.png');
        }

        @include mobileScreenVideoAPP();

    }


    @media (max-width: $xxs) {

        .video-app-content {
            height: 481px;
            @include responsiveVideoBackground('../../app/bento-components/video-app/media/pbs_app_resolution_375.png');

            .video-app-content-left {
                flex: 1;

                .text-description-container {
                    font-size: 38px;
                    display: flex;
                    flex-direction: column;
                }
            }

            .video-app-content-right {
                .pbs-content {
                    flex-direction: column;
                    margin-top: 10px;

                    .pbs-logo-container {
                        width: 172px;
                        height: 74px;
                    }

                    .pbs-download-app-container {
                        .download-app-content {
                            font-weight: $black;
                            font-size: 25px;
                            max-width: 173px;
                            max-height: 43px;
                        }

                        .app-additional-info {
                            font-size: 21px;
                            font-weight: $normal;
                        }
                    }

                }

                .additional-info {
                    font-size: 12px;
                    font-weight: $normal;
                    padding: 0 50px 12px 50px;
                    margin-top: 25px;
                }
            }
        }
    }

}


.span3 .component .video-app-component .video-app-content .video-app-content-right {
    .additional-info {
        margin-top: 25px;
    }
}
